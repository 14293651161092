<template>
  <div>
    <a-modal
      v-model="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      centered
      @cancel="cancel"
      @ok="confirm"
    >
      <div slot="title">{{ form.id ? $t("编辑分类") : $t("新增分类") }}</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
          <a-form-model-item prop="name" :label="$t('分类名称')">
            <a-input v-model="form.name" />
          </a-form-model-item>
          <a-form-model-item prop="remark" :label="$t('备注')">
            <a-input v-model="form.remark" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { categoriesCreate, categoriesUpdate } from "@/api/categories";
import rules from "./rules.js";

export default {
  props: ["visible", "form"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules,
      loading: false,
    };
  },
  methods: {
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? categoriesUpdate : categoriesCreate;
          func(this.form)
            .then((data) => {
              this.$message.success(this.form.id ? this.$t("修改成功") : this.$t("新增成功"));
              this.$emit(this.form.id ? "update" : "create", data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style scoped></style>
